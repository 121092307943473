import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import hautausmaa from "../images/hautausmaa.jpg"
import jouni_laaksonen from "../images/jouni_laaksonen1.jpg"
import satu_vuorinen from "../images/satu_vuorinen1.jpg"
import raigo_liiman from "../images/raigo_liiman.jpg"
import Kosola_Harri from "../images/Kosola_Harri1.jpg"
import matti_halme from "../images/matti_halme1.jpg"
import wilen_mikael from "../images/mikael_wilen1.jpg"
import aulikki_luukkanen from "../images/aulikki_luukkanen.jpg"
import juha_ylinen from "../images/juha_ylinen.jpg"

const Liittohallitus = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Liittohallitus" />
    <div className="hero-area full-container">
      <div className="page-banner parallax">
        <div className="container">
          <h1>Liittohallitus</h1>
        </div>
      </div>
    </div>
    <div className="spacer-60" />
    <div className="row">
      <div className="col-md-8 col-md-offset-2">
        <img
          className="pull-right padding-l25 w-6/12"
          src={hautausmaa}
          alt=""
        />

        <p>
          Liittoa edustaa ja sen asioita hoitaa liittohallitus, jonka
          muodostavat 5 – 7 jäsentä. Jäsenet valitaan kolmeksi vuodeksi
          kerrallaan ja vuosittain kolmannes on erovuorossa. Liittohallitus
          valitsee keskuudestaan puheenjohtajan ja varapuheenjohtajan, jotka
          kutsutaan myös liiton puheenjohtajaksi ja varapuheenjohtajaksi.
          Liittohallituksen sihteerinä ja esittelijänä toimii liiton
          pääsihteeri, joka voi olla liittohallituksen jäsen. Pääsihteeri on
          liiton toimeenpaneva toimenhaltija, jonka liittohallitus valitsee.
          Liittohallitus valitsee keskuudestaan tai ulkopuolelta
          taloudenhoitajan sekä muut toimihenkilöt.
        </p>

        <p>
          Jos liittohallituksen jäsen eroaa ennen toimikautensa päättymistä,
          valitaan seuraavassa varsinaisessa tai ylimääräisessä
          liittokokouksessa uusi jäsen hänen tilalleen jäljellä olevaksi
          toimikaudeksi. Liittohallitus kokoontuu puheenjohtajan kutsusta.
          Liittohallituksen kokouksissa on pidettävä pöytäkirjaa.
        </p>

        <p>
          Liittohallitus voi nimetä valiokuntia ja toimikuntia. Liittohallitus
          on päätösvaltainen, kun vähintään puheenjohtaja tai varapuheenjohtaja
          ja kolme sen jäsentä on kokouksessa läsnä. äänten käydessä tasan
          puheenjohtajan ääni on ratkaiseva.
        </p>
      </div>
    </div>
    <div className="pt-10 ">
      <h2 className="text-center">Hallitus</h2>

      <ul className="flex flex-wrap m-0">
        
      <li className="w-full xs:w-6/12 md:w-4/12 card-item">
          <div className="flex flex-col h-full">
            <img src={wilen_mikael} alt="" />
            <div className="card-item-content">
              <h3>Wilén Mikael</h3>
              <span className="meta-data">Puheenjohtaja</span>
              <div className="team-item-excerpt">
                <p>
                  Toimitusjohtaja, Krematoriosäätiö r.s.
                  <br />
                  Puh. 0505695555
                  <br />
                  Sähköposti: mikael.wilen@krematorio.com
                  <br />
                </p>
              </div>
            </div>
          </div>
        </li>
        <li className="w-full xs:w-6/12 md:w-4/12 card-item">
          <div className="flex flex-col h-full">
            <img src={satu_vuorinen} alt="" />
            <div className="card-item-content">
              <h3>Vuorinen Satu</h3>
              <span className="meta-data">Varapuheenjohtaja</span>
              <div className="team-item-excerpt">
                <p>
                  Seurakuntapuutarhuri, Järvenpään seurakunta
                  <br />
                  Puh.050 443 4925
                  <br />
                  Sähköposti: satu.vuorinen@evl.fi
                  <br />
                  Työ os. Kaukotie 48, 04410 Järvenpää
                  <br />
                </p>
              </div>
            </div>
          </div>
        </li>
        
        <li className="w-full xs:w-6/12 md:w-4/12 card-item">
          <div className="flex flex-col h-full">
            <img src={jouni_laaksonen} alt="" />
            <div className="card-item-content">
              <h3>Laaksonen Jouni</h3>
              <span className="meta-data">Jäsen</span>
              <div className="team-item-excerpt">
                <p>
                  Ylipuutarhuri, Rauman seurakunta
                  <br />
                  PL 158
                  <br />
                  26101 Rauma
                  <br />
                  Puh. 044-7691411
                  <br />
                  Faksi: 02-8229568
                  <br />
                  Sähköposti: jouni.laaksonen@evl.fi
                  <br />
                  Koti: Linnamaantie 642, 27100 Eurajoki
                  <br />
                  Puh. 040-5502203
                </p>
              </div>
            </div>
          </div>
        </li>
        
        
        <li className="w-full xs:w-6/12 md:w-4/12 card-item">
          <div className="flex flex-col h-full">
            <img src={raigo_liiman} alt="" />
            <div className="card-item-content">
              <h3>Liiman Raigo</h3>
              <span className="meta-data">Jäsen</span>
              <div className="team-item-excerpt">
                <p>
                  Seurakuntapastori, Espoon tuomiokirkkoseurakunta
                  <br />
                  Puh. 050 438 016
                  <br />
                  Sähköposti: raigo.liiman@evl.fi
                </p>
              </div>
            </div>
          </div>
        </li>
        <li className="w-full xs:w-6/12 md:w-4/12 card-item">
          <div className="flex flex-col h-full">
            <img src={Kosola_Harri} alt="" />
            <div className="card-item-content">
              <h3>Kosola Harri</h3>
              <span className="meta-data">Jäsen</span>
              <div className="team-item-excerpt">
                <p>
                  Työnjohtaja, Seinäjoen seurakunta
                  <br />
                  Puh. 050 3620059
                  <br />
                  Sähköposti: harri.kosola@evl.fi
                  <br />
                </p>
              </div>
            </div>
          </div>
        </li>
        <li className="w-full xs:w-6/12 md:w-4/12 card-item">
          <div className="flex flex-col h-full">
            <img src={aulikki_luukkanen} alt="" />
            <div className="card-item-content">
              <h3>Raunemo Aulikki</h3>
              <span className="meta-data">Jäsen</span>
              <div className="team-item-excerpt">
                <p>
                  Työnjohtaja, Hyvinkään seurakunta
                  <br />
                  Puh. 0400 545160
                  <br />
                  Sähköposti: aulikki.raunemo@evl.fi
                  <br />
                </p>
              </div>
            </div>
          </div>
        </li>
        <li className="w-full xs:w-6/12 md:w-4/12 card-item">
          <div className="flex flex-col h-full">
            <img src={juha_ylinen} alt="" />
            <div className="card-item-content">
              <h3>Juha Ylinen</h3>
              <span className="meta-data">Jäsen</span>
              <div className="team-item-excerpt">
                <p>Puistopäällikkö, Lapuan Tuomiokirkkoseurakunta
                  <br/>
                  Puh. 0440 188532
                  <br />
                  Sähköposti: juha.ylinen@evl.fi
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <h2 className="text-center">Pääsihteeri</h2>
      <ul className="flex flex-wrap m-0">
        <li className="w-full xs:w-6/12 md:w-4/12 card-item">
          <div className="flex flex-col h-full">
            <img src={matti_halme} alt="" />
            <div className="card-item-content">
              <h3>Halme Matti</h3>
              <span className="meta-data">Pääsihteeri</span>
              <div className="team-item-excerpt">
                <p>
                  Puh. 0400 618 872
                  <br />
                  Sähköposti: info@shk.fi
                  <br />
                  Koti: Koivurinne 4 18100 Heinola
                  <br />
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div className="spacer-50" />
    </div>
  </Layout>
)

export default Liittohallitus
